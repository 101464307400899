let formContactoValidator = null;

const formContactoInitValidator = function () {
    if (formContactoValidator == null) {
        formContactoValidator = $('#form-contacto').validate({
            rules: {
                contacto_nombre: {
                    required: true
                },
                contacto_telefono: {
                    required: true
                },
                contacto_email: {
                    required: true,
                    email: true
                },
                contacto_comentarios: {
                    required: true
                },
                contacto_acepto: {
                    required: true
                }
            },
            messages: {
                contacto_nombre: {
                    required: 'Escriba su nombre, por favor'
                },
                contacto_telefono: {
                    required: 'Escriba su teléfono, por favor'
                },
                contacto_email: {
                    required: 'Escriba su email, por favor',
                    email: 'El email no es correcto'
                },
                contacto_comentarios: {
                    required: 'Escriba sus comentarios, por favor'
                },
                contacto_acepto: {
                    required: 'Es necesario leer y aceptar el aviso legal'
                }
            },
            errorElement: 'span',
            errorClass: 'text-danger',
            errorPlacement: function (error, element) {
                error.insertAfter(element);
            },
            success: function (label, element) {
                if ($(element).next('span')[0])
                    $(element).next('span')[0].remove();
            },
            highlight: function (element, errorClass, validClass) {
                //$(element).closest('.form-group').addClass('alert').addClass('alert-danger');
            },
            unhighlight: function (element, errorClass, validClass) {
                //$(element).closest('.form-group').removeClass('alert').removeClass('alert-danger');
            }
        });

    }
};
const formContactoSend = function () {
    $('#contacto-success').addClass('d-none');
    $('#contacto-error').addClass('d-none');
    formContactoInitValidator();
    let url = '/api/formulario/contacto';
    if (!$('#form-contacto').valid()) {
        return false;
    }
    $('#btn-contacto-send').addClass('d-none');
    $('#btn-contacto-sending').removeClass('d-none');
    const payload = {
        nombre: $('#contacto_nombre').val(),
        telefono: $('#contacto_telefono').val(),
        email: $('#contacto_email').val(),
        comentarios: $('#contacto_comentarios').val(),
        aceptaAvisoLegal: $('#contacto_acepto').is(":checked")
    };
    $.ajax({
        headers: {
            Accept: 'application/json; charset=utf-8',
            'Content-Type': 'application/json; charset=utf-8'
        },
        type: 'POST',
        url: url,
        data: JSON.stringify(payload),
        dataType: 'json',
        success: function (data, status, jqXHR) {
            $('#btn-contacto-send').removeClass('d-none');
            $('#btn-contacto-sending').addClass('d-none');
            if ('OK' == data.status) {
                $('#contacto_nombre').val('');
                $('#contacto_telefono').val('');
                $('#contacto_email').val('');
                $('#contacto_comentarios').val('');
                $('#contacto-success').html(data.message).removeClass('d-none');
                //document.location.href = './gracias.html';
            } else {
                $('#contact-error').html(data.message).removeClass('d-none');
            }
        },
        error: function (jqXHR, status) {
            $('#btn-contacto-send').removeClass('d-none');
            $('#btn-contacto-sending').addClass('d-none');
            $('#contacto-error').html('Su mensaje no ha podido ser entregado, por favor inténtelo más tarde.<br />Si el problema persiste, póngase en contacto con nosotros en: disanz@disanz.es<br />Disculpe las molestias.').removeClass('d-none');
        }
    });
    return false;
};

window.formContactoSend = formContactoSend;
