let formForgotpwdValidator = null;

const formForgotpwdInitValidator = function () {
    if (formForgotpwdValidator == null) {
        formForgotpwdValidator = $('#form-forgotpwd').validate({
            rules: {
                forgotpwd_cif: {
                    required: true
                }
            },
            messages: {
                forgotpwd_cif: {
                    required: 'Escriba su CIF, por favor'
                }
            },
            errorElement: 'span',
            errorClass: 'text-danger',
            errorPlacement: function (error, element) {
                error.insertAfter(element);
            },
            success: function (label, element) {
                if ($(element).next('span')[0])
                    $(element).next('span')[0].remove();
            },
            highlight: function (element, errorClass, validClass) {
                //$(element).closest('.form-control').addClass('alert').addClass('alert-danger');
            },
            unhighlight: function (element, errorClass, validClass) {
                //$(element).closest('.form-control').removeClass('alert').removeClass('alert-danger');
            }
        });

    }
};
const formForgotpwdSend = function () {
    $('#forgotpwd-success').addClass('d-none');
    $('#forgotpwd-error').addClass('d-none');
    formForgotpwdInitValidator();
    let url = '/api/formulario/forgotpwd';
    if (!$('#form-forgotpwd').valid()) {
        return false;
    }
    $('#btn-forgotpwd-send').addClass('d-none');
    $('#btn-forgotpwd-sending').removeClass('d-none');
    const payload = {
        cif: $('#forgotpwd_cif').val()
    };
    $.ajax({
        headers: {
            Accept: 'application/json; charset=utf-8',
            'Content-Type': 'application/json; charset=utf-8'
        },
        type: 'POST',
        url: url,
        data: JSON.stringify(payload),
        dataType: 'json',
        success: function (data, status, jqXHR) {
            $('#btn-forgotpwd-send').removeClass('d-none');
            $('#btn-forgotpwd-sending').addClass('d-none');
            if ('OK' == data.status) {
                $('#forgotpwd_email').val('');
                //$('#forgotpwd-success').html(data.message).removeClass('d-none');
                new Messi('Le hemos enviado un correo con la nueva contraseña', {
                    title: 'Mensaje',
                    buttons: [{ id: 0, label: 'Aceptar', val: 'X'}],
                    callback: function(val) {
                        document.location.href = 'login-register.html';
                    }
                 });
            } else {
                $('#forgotpwd-error').html(data.message).removeClass('d-none');
            }
        },
        error: function (jqXHR, status) {
            $('#btn-forgotpwd-send').removeClass('d-none');
            $('#btn-forgotpwd-sending').addClass('d-none');
            $('#forgotpwd-error').html('Su mensaje no ha podido ser entregado, por favor inténtelo más tarde.<br />Si el problema persiste, póngase en contacto con nosotros en: disanz@disanz.es<br />Disculpe las molestias.').removeClass('d-none');
        }
    });
    return false;
};

window.formForgotpwdSend = formForgotpwdSend;
