let formCarrito2Validator = null;

const formCarrito2InitValidator = function () {
    if (formCarrito2Validator == null) {
        $.validator.addMethod('iban', function (value, element) {
            return checkIBAN(value);
        });
        formCarrito2Validator = $('#form-carrito2').validate({
            rules: {
                carrito2_email: {
                    email: true
                },
                carrito2_entrega_direccion: {
                    required: {
                        depends: function (element) {
                            return !document.getElementById('carrito2_mismaentrega').checked;
                        }
                    }
                },
                carrito2_entrega_poblacion: {
                    required: {
                        depends: function (element) {
                            return !document.getElementById('carrito2_mismaentrega').checked;
                        }
                    }
                },
                carrito2_entrega_telefono: {
                    required: {
                        depends: function (element) {
                            return !document.getElementById('carrito2_mismaentrega').checked;
                        }
                    }
                },
                carrito2_formapago: {
                    required: true
                },
                carrito2_ccc_iban: {
                    required: {
                        depends: function (element) {
                            const e = document.getElementById('carrito2_formapago');
                            const formapago = e.options[e.selectedIndex].value;
                            return ('DO' == formapago);
                        }
                    },
                    iban: true
                },
                carrito2_ccc_titular: {
                    required: {
                        depends: function (element) {
                            const e = document.getElementById('carrito2_formapago');
                            const formapago = e.options[e.selectedIndex].value;
                            return ('DO' == formapago);
                        }
                    }
                },
                carrito2_acepto3: {
                    required: true
                }
            },
            messages: {
                carrito2_email: {
                    email: 'El email no es correcto'
                },
                carrito2_entrega_direccion: {
                    required: 'Inidique la dirección de entrega, por favor'
                },
                carrito2_entrega_poblacion: {
                    required: 'Inidique la población de entrega, por favor'
                },
                carrito2_entrega_telefono: {
                    required: 'Inidique un teléfono para la entrega, por favor'
                },
                carrito2_formapago: {
                    required: 'Inidique la forma de pago, por favor'
                },
                carrito2_ccc_iban: {
                    required: 'Inidique el IBAN, por favor',
                    iban: 'El IBAN no es correcto'
                },
                carrito2_ccc_titular: {
                    required: 'Inidique el titular de la cuenta, por favor'
                },
                carrito2_acepto3: {
                    required: 'Es necesario leer y aceptar el aviso legal'
                }
            },
            errorElement: 'span',
            errorClass: 'text-danger',
            errorPlacement: function (error, element) {
                error.insertAfter(element);
            },
            success: function (label, element) {
                if ($(element).next('span')[0])
                    $(element).next('span')[0].remove();
            },
            highlight: function (element, errorClass, validClass) {
                //$(element).closest('.form-group').addClass('alert').addClass('alert-danger');
            },
            unhighlight: function (element, errorClass, validClass) {
                //$(element).closest('.form-group').removeClass('alert').removeClass('alert-danger');
            }
        });

    }
};
const formCarrito2Send = function () {
    let error = false;
    $('#carrito2-success').addClass('d-none');
    $('#carrito2-error').addClass('d-none');
    formCarrito2InitValidator();
    const e = document.getElementById('carrito2_formapago');
    const formapago = e.options[e.selectedIndex].value;
    if ($('#carrito2_email').val() == '') {
        $('#carrito2-error').html('Debe indicar el email').removeClass('d-none');
        error = true;
    }
    if (e.selectedIndex == 0) {
        $('#carrito2-error').html('Debe indicar la forma de pago').removeClass('d-none');
        error = true;
    }
    if (!document.getElementById('carrito2_es_particular').checked &&
        !document.getElementById('carrito2_es_direccion').checked &&
        !document.getElementById('carrito2_es_cooperativa').checked) {
        $('#carrito2-error').html('Debe indicar si se trata de Particular, Dirección o Cooperativa').removeClass('d-none');
        error = true;
    }
    if (!$('#form-carrito2').valid()) {
        $('#carrito2-error').html('Revise el formulario, por favor').removeClass('d-none');
        error = true;
    }
    if (error) {
        return;
    }
    let url = '/api/formulario/carrito2';
    $('#btn-carrito2-send').addClass('d-none');
    $('#btn-carrito2-sending').removeClass('d-none');
    const payload = {
        contacto: $('#carrito2_contacto').val(),
        email: $('#carrito2_email').val(),
        curso: $('#carrito2_curso').val(),
        particular: document.getElementById('carrito2_es_particular').checked,
        direccion: document.getElementById('carrito2_es_direccion').checked,
        cooperativa: document.getElementById('carrito2_es_cooperativa').checked,
        observaciones: $('#carrito2_observaciones').val(),
        entregaIgualFacturacion: document.getElementById('carrito2_mismaentrega').checked,
        entregaNombre: $('#carrito2_entrega_nombre').val(),
        entregaContacto: $('#carrito2_entrega_contacto').val(),
        entregaDireccion: $('#carrito2_entrega_direccion').val(),
        entregaPoblacion: $('#carrito2_entrega_poblacion').val(),
        entregaTelefono: $('#carrito2_entrega_telefono').val(),
        entregaFax: $('#carrito2_entrega_fax').val(),
        formaPago: formapago,
        iban: $('#carrito2_ccc_iban').val(),
        titular: $('#carrito2_ccc_titular').val(),
        aceptaCondiciones: $('#carrito2_acepto1').is(":checked"),
        aceptaRealizaPedido: $('#carrito2_acepto2').is(":checked"),
        aceptaAvisoLegal: $('#carrito2_acepto3').is(":checked")
    };
    $.ajax({
        headers: {
            Accept: 'application/json; charset=utf-8',
            'Content-Type': 'application/json; charset=utf-8'
        },
        type: 'POST',
        url: url,
        data: JSON.stringify(payload),
        dataType: 'json',
        success: function (data, status, jqXHR) {
            if ('OK' == data.status) {
                document.location.href = 'carrito3.aspx';
            } else {
                $('#btn-carrito2-send').removeClass('d-none');
                $('#btn-carrito2-sending').addClass('d-none');
                $('#carrito2-error').html(data.message).removeClass('d-none');
            }
        },
        error: function (jqXHR, status) {
            $('#btn-carrito2-send').removeClass('d-none');
            $('#btn-carrito2-sending').addClass('d-none');
            $('#carrito2-error').html('Su mensaje no ha podido ser entregado, por favor inténtelo más tarde.<br />Si el problema persiste, póngase en contacto con nosotros en: disanz@disanz.es<br />Disculpe las molestias.').removeClass('d-none');
        }
    });
    return false;
};

const formCarrito2OnMismaEntregaChanged = function () {
    const mismaentrega = document.getElementById('carrito2_mismaentrega').checked;
    if (mismaentrega)
        document.getElementById('carrito2_datos_entrega').classList.add('d-none');
    else
        document.getElementById('carrito2_datos_entrega').classList.remove('d-none');
}

const formCarrito2OnFormaPagoChanged = function () {
    const e = document.getElementById('carrito2_formapago');
    const formapago = e.options[e.selectedIndex].value;
    if ('DO' == formapago)
        document.getElementById('carrito2_datos_domiciliacion').classList.remove('d-none');
    else
        document.getElementById('carrito2_datos_domiciliacion').classList.add('d-none');
}

const checkIBAN = function (IBAN) {
    //Se pasa a Mayusculas
    IBAN = IBAN.toUpperCase();
    //Se quita los blancos de principio y final.
    IBAN = IBAN.trim();
    IBAN = IBAN.replace(/\s/g, ""); //Y se quita los espacios en blanco dentro de la cadena

    //La longitud debe ser siempre de 24 caracteres
    if (IBAN.length != 24) {
        return false;
    }

    // Se coge las primeras dos letras y se pasan a números
    const letra1 = IBAN.substring(0, 1);
    const letra2 = IBAN.substring(1, 2);
    const num1 = getnumIBAN(letra1);
    const num2 = getnumIBAN(letra2);
    //Se sustituye las letras por números.
    let isbanaux = String(num1) + String(num2) + IBAN.substring(2);
    // Se mueve los 6 primeros caracteres al final de la cadena.
    isbanaux = isbanaux.substring(6) + isbanaux.substring(0, 6);

    //Se calcula el resto, llamando a la función modulo97, definida más abajo
    let resto = modulo97(isbanaux);
    if (resto == 1) {
        return true;
    } else {
        return false;
    }
}
const modulo97 = function (iban) {
    const parts = Math.ceil(iban.length / 7);
    let remainer = "";

    for (var i = 1; i <= parts; i++) {
        remainer = String(parseFloat(remainer + iban.substr((i - 1) * 7, 7)) % 97);
    }

    return remainer;
}
const getnumIBAN = function (letra) {
    const ls_letras = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    return ls_letras.search(letra) + 10;
}

window.formCarrito2Send = formCarrito2Send;
window.formCarrito2OnMismaEntregaChanged = formCarrito2OnMismaEntregaChanged;
window.formCarrito2OnFormaPagoChanged = formCarrito2OnFormaPagoChanged;
