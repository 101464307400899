let formRegistroValidator = null;

const formRegistroInitValidator = function () {
    if (formRegistroValidator == null) {
        formRegistroValidator = $('#form-registro').validate({
            rules: {
                registro_empresa: {
                    required: true
                },
                registro_cif: {
                    required: true
                },
                registro_domicilio: {
                    required: true
                },
                registro_poblacion: {
                    required: true
                },
                registro_codpostal: {
                    required: true
                },
                registro_provincia: {
                    required: true
                },
                registro_telefono: {
                    required: true
                },
                registro_fax: {
                    required: true
                },
                registro_email: {
                    required: true,
                    email: true
                },
                registro_conocido: {
                    required: true
                },
                registro_comentarios: {
                    required: true
                },
                registro_acepto_aviso_legal: {
                    required: true
                },
                registro_acepto_politica_privacidad: {
                    required: true
                },
                registro_acepto_recibir_comunicaciones: {
                    required: false
                }
            },
            messages: {
                registro_empresa: {
                    required: 'Escriba su empresa, por favor'
                },
                registro_cif: {
                    required: 'Escriba su CIF, por favor'
                },
                registro_domicilio: {
                    required: 'Escriba su domicilio, por favor'
                },
                registro_poblacion: {
                    required: 'Escriba su población, por favor'
                },
                registro_codpostal: {
                    required: 'Escriba su código postal, por favor'
                },
                registro_provincia: {
                    required: 'Escriba su provincia, por favor'
                },
                registro_telefono: {
                    required: 'Escriba su teléfono, por favor'
                },
                registro_fax: {
                    required: 'Escriba su fax, por favor'
                },
                registro_email: {
                    required: 'Escriba su email, por favor',
                    email: 'El email no es correcto'
                },
                registro_conocido: {
                    required: 'Escriba cómo nos ha conocido, por favor'
                },
                registro_comentarios: {
                    required: 'Escriba sus comentarios, por favor'
                },
                registro_acepto_aviso_legal: {
                    required: 'Es necesario leer y aceptar el aviso legal'
                },
                registro_acepto_politica_privacidad: {
                    required: 'Es necesario leer y aceptar la política de privacidad'
                },
                registro_acepto_recibir_comunicaciones: {
                    required: '*'
                }
            },
            errorElement: 'span',
            errorClass: 'text-danger',
            errorPlacement: function (error, element) {
                error.insertAfter(element);
            },
            success: function (label, element) {
                if ($(element).next('span')[0])
                    $(element).next('span')[0].remove();
            },
            highlight: function (element, errorClass, validClass) {
                //$(element).closest('.form-group').addClass('alert').addClass('alert-danger');
            },
            unhighlight: function (element, errorClass, validClass) {
                //$(element).closest('.form-group').removeClass('alert').removeClass('alert-danger');
            }
        });

    }
};
const formRegistroSend = function () {
    $('#registro-success').addClass('d-none');
    $('#registro-error').addClass('d-none');
    formRegistroInitValidator();
    let url = '/api/formulario/registro';
    if (!$('#form-registro').valid()) {
        return false;
    }
    $('#btn-registro-send').addClass('d-none');
    $('#btn-registro-sending').removeClass('d-none');
    const payload = {
        empresa: $('#registro_empresa').val(),
        cif: $('#registro_cif').val(),
        domicilio: $('#registro_domicilio').val(),
        poblacion: $('#registro_poblacion').val(),
        codpostal: $('#registro_codpostal').val(),
        provincia: $('#registro_provincia').val(),
        telefono: $('#registro_telefono').val(),
        fax: $('#registro_fax').val(),
        email: $('#registro_email').val(),
        conocido: $('#registro_conocido').val(),
        comentarios: $('#registro_comentarios').val(),
        aceptaAvisoLegal: $('#registro_acepto_aviso_legal').is(":checked"),
        aceptaPoliticaPrivacidad: $('#registro_acepto_politica_privacidad').is(":checked"),
        aceptaRecibirComunicaciones: $('#registro_acepto_recibir_comunicaciones').is(":checked")
    };
    $.ajax({
        headers: {
            Accept: 'application/json; charset=utf-8',
            'Content-Type': 'application/json; charset=utf-8'
        },
        type: 'POST',
        url: url,
        data: JSON.stringify(payload),
        dataType: 'json',
        success: function (data, status, jqXHR) {
            $('#btn-registro-send').removeClass('d-none');
            $('#btn-registro-sending').addClass('d-none');
            if ('OK' == data.status) {
                $('#registro_empresa').val('');
                $('#registro_cif').val('');
                $('#registro_domicilio').val('');
                $('#registro_poblacion').val('');
                $('#registro_codpostal').val('');
                $('#registro_provincia').val('');
                $('#registro_telefono').val('');
                $('#registro_fax').val('');
                $('#registro_email').val('');
                $('#registro_conocido').val('');
                $('#registro_comentarios').val('');
                $('#registro-success').html(data.message).removeClass('d-none');
                //document.location.href = './gracias.html';
            } else {
                $('#registro-error').html(data.message).removeClass('d-none');
            }
        },
        error: function (jqXHR, status) {
            $('#btn-registro-send').removeClass('d-none');
            $('#btn-registro-sending').addClass('d-none');
            $('#registro-error').html('Su mensaje no ha podido ser entregado, por favor inténtelo más tarde.<br />Si el problema persiste, póngase en contacto con nosotros en: disanz@disanz.es<br />Disculpe las molestias.').removeClass('d-none');
        }
    });
    return false;
};

window.formRegistroSend = formRegistroSend;
