let formLoginValidator = null;

const formLoginInitValidator = function () {
    if (formLoginValidator == null) {
        formLoginValidator = $('#form-login').validate({
            rules: {
                login_usuario: {
                    required: true
                },
                login_clave: {
                    required: true
                }
            },
            messages: {
                login_usuario: {
                    required: 'Escriba su usuario, por favor'
                },
                login_clave: {
                    required: 'Escriba su contraseña, por favor'
                }
            },
            errorElement: 'span',
            errorClass: 'text-danger',
            errorPlacement: function (error, element) {
                error.insertAfter(element);
            },
            success: function (label, element) {
                if ($(element).next('span')[0])
                    $(element).next('span')[0].remove();
            },
            highlight: function (element, errorClass, validClass) {
                //$(element).closest('.form-group').addClass('alert').addClass('alert-danger');
            },
            unhighlight: function (element, errorClass, validClass) {
                //$(element).closest('.form-group').removeClass('alert').removeClass('alert-danger');
            }
        });

    }
};
const formLoginSend = function () {
    const urlParams = new URLSearchParams(location.search);
    let id; let cant; let returnUrl;
    if (urlParams.has('id') && urlParams.has('cant')) {
        id = urlParams.get('id');
        cant = urlParams.get('cant');
    }
    if (urlParams.has('url')) {
        returnUrl = urlParams.get('url');
    }
    if (!returnUrl)
        returnUrl = 'default.aspx';
    $('#login-success').addClass('d-none');
    $('#login-error').addClass('d-none');
    formLoginInitValidator();
    let url = '/api/formulario/login';
    if (!$('#form-login').valid()) {
        return false;
    }
    $('#btn-login-send').addClass('d-none');
    $('#btn-login-sending').removeClass('d-none');
    const payload = {
        usuario: $('#login_usuario').val(),
        clave: $('#login_clave').val(),
        idProducto: id,
        cantidad: cant
    };
    $.ajax({
        headers: {
            Accept: 'application/json; charset=utf-8',
            'Content-Type': 'application/json; charset=utf-8'
        },
        type: 'POST',
        url: url,
        data: JSON.stringify(payload),
        dataType: 'json',
        success: function (data, status, jqXHR) {
            if ('OK' == data.status) {
                $('#login_usuario').val('');
                $('#login_clave').val('');
                document.location.href = returnUrl;
            } else {
                $('#btn-login-send').removeClass('d-none');
                $('#btn-login-sending').addClass('d-none');
                $('#login-error').html(data.message).removeClass('d-none');
            }
        },
        error: function (jqXHR, status) {
            $('#btn-login-send').removeClass('d-none');
            $('#btn-login-sending').addClass('d-none');
            $('#login-error').html('Ocurrió un problema, por favor inténtelo más tarde.<br />Si el problema persiste, póngase en contacto con nosotros en: disanz@disanz.es<br />Disculpe las molestias.').removeClass('d-none');
        }
    });
    return false;
};

window.formLoginSend = formLoginSend;
