let formNewsletterValidator = null;

const formNewsletterInitValidator = function () {
    if (formNewsletterValidator == null) {
        formNewsletterValidator = $('#form-newsletter').validate({
            rules: {
                newsletter_email: {
                    required: true,
                    email: true
                }
            },
            messages: {
                newsletter_email: {
                    required: 'Escriba su email, por favor',
                    email: 'El email no es correcto'
                }
            },
            errorElement: 'span',
            errorClass: 'text-danger',
            errorPlacement: function (error, element) {
                error.insertAfter($(element).closest('.input-group'));
            },
            success: function (label, element) {
                if ($(element).next('span')[0])
                    $(element).next('span')[0].remove();
            },
            highlight: function (element, errorClass, validClass) {
                //$(element).closest('.form-control').addClass('alert').addClass('alert-danger');
            },
            unhighlight: function (element, errorClass, validClass) {
                //$(element).closest('.form-control').removeClass('alert').removeClass('alert-danger');
            }
        });

    }
};
const formNewsletterSend = function () {
    $('#newsletter-success').addClass('d-none');
    $('#newsletter-error').addClass('d-none');
    formNewsletterInitValidator();
    let url = '/api/formulario/newsletter';
    if (!$('#form-newsletter').valid()) {
        return false;
    }
    $('#btn-newsletter-send').addClass('d-none');
    $('#btn-newsletter-sending').removeClass('d-none');
    const payload = {
        email: $('#newsletter_email').val()
    };
    $.ajax({
        headers: {
            Accept: 'application/json; charset=utf-8',
            'Content-Type': 'application/json; charset=utf-8'
        },
        type: 'POST',
        url: url,
        data: JSON.stringify(payload),
        dataType: 'json',
        success: function (data, status, jqXHR) {
            $('#btn-newsletter-send').removeClass('d-none');
            $('#btn-newsletter-sending').addClass('d-none');
            if ('OK' == data.status) {
                $('#newsletter_email').val('');
                $('#newsletter-success').html(data.message).removeClass('d-none');
                //document.location.href = './gracias.html';
            } else {
                $('#newsletter-error').html(data.message).removeClass('d-none');
            }
        },
        error: function (jqXHR, status) {
            $('#btn-newsletter-send').removeClass('d-none');
            $('#btn-newsletter-sending').addClass('d-none');
            $('#newsletter-error').html('Su mensaje no ha podido ser entregado, por favor inténtelo más tarde.<br />Si el problema persiste, póngase en contacto con nosotros en: disanz@disanz.es<br />Disculpe las molestias.').removeClass('d-none');
        }
    });
    return false;
};

window.formNewsletterSend = formNewsletterSend;
