const obtenerObjeto = function (idObjeto) {
    if (document.getElementById)
        return document.getElementById(idObjeto);
    else if (document.all)
        return document.all[idObjeto];
}
const isInteger = function(value) {
    return /^-?\d+$/.test(value);
}
let xmlhttp;
let ajaxResult;
if (!xmlhttp && typeof XMLHttpRequest != 'undefined') {
    try {
        xmlhttp = new XMLHttpRequest();
    } catch (e) {
        xmlhttp = false;
    }
}
const cesta_text_inc = function(id_referencia) {
    const inputtext = obtenerObjeto("ref_" + id_referencia);
    let cantidad = inputtext.value;
    if (cantidad == '' || !isInteger(cantidad))
        cantidad = '1';
    else if (cantidad < 0)
        cantidad = 1;
    else if (cantidad < 9999)
        cantidad = parseInt(cantidad) + 1;
    else
        return;
    inputtext.value = cantidad;
}
const cesta_text_dec = function(id_referencia) {
    const inputtext = obtenerObjeto("ref_" + id_referencia);
    let cantidad = inputtext.value;
    if (!isInteger(cantidad))
        cantidad = 0;
    else if (cantidad > 0)
        cantidad = parseInt(cantidad) - 1;
    else
        cantidad = 0;
    inputtext.value = cantidad;
}
const cesta_put = function(id_referencia, reloadPage = false) {
    if (xmlhttp) {
        const inputtext = obtenerObjeto("ref_" + id_referencia);
        let cantidad = inputtext.value;
        if (!isInteger(cantidad))
            return false;
        if (cantidad == '0')
            return false;
        ajaxResult = null;
        const url = "ajax/cesta_put.aspx?id_referencia=" + id_referencia + "&cantidad=" + cantidad;
        xmlhttp.open("GET", url, false);
        xmlhttp.onReadyStateChange = respuesta_cesta_put;
        xmlhttp.send(null);
        if (ajaxResult == null)
            respuesta_cesta_put();
        const status = ajaxResult[0];
        const numProductosCesta = ajaxResult[1];
        if ("OK" == status && numProductosCesta != '') {
            if (reloadPage) {
                document.location.reload(true);
            } else {
                const cesta_num_productos = obtenerObjeto("cesta_num_productos");
                cesta_num_productos.innerHTML = numProductosCesta;
            }
            return true;
        }
        //return ajaxResult;
    }
}
const respuesta_cesta_put = function(id_referencia) {
    if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
        const resultadoXml = xmlhttp.responseXML;
        const objNodeList = resultadoXml.getElementsByTagName("result");
        let status = "KO";
        let numProductosCesta = "";
        if (objNodeList.length == 1) {
            status = objNodeList.item(0).attributes.getNamedItem("status").value;
            numProductosCesta = objNodeList.item(0).attributes.getNamedItem("numProductosCesta").value;
        }
        ajaxResult = new Array(2);
        ajaxResult[0] = status;
        ajaxResult[1] = numProductosCesta;
    }
}
const cesta_inc = function(id_referencia) {
    const inputtext = obtenerObjeto("ref_" + id_referencia);
    let cantidad = inputtext.value;
    if (cantidad == '')
        cantidad = '1';
    else if (!isInteger(cantidad))
        return;
    else if (cantidad < 0)
        cantidad = 1;
    else if (cantidad < 9999)
        cantidad = parseInt(cantidad) + 1;
    else
        return;
    if (xmlhttp) {
        ajaxResult = null;
        const url = "ajax/cesta_put.aspx?id_referencia=" + id_referencia + "&cantidad=" + cantidad;
        xmlhttp.open("GET", url, false);
        xmlhttp.onReadyStateChange = respuesta_cesta_put;
        xmlhttp.send(null);
        if (ajaxResult == null)
            respuesta_cesta_put();
        let status = ajaxResult[0];
        let numProductosCesta = ajaxResult[1];
        if ("OK" == status && numProductosCesta != '') {
            document.location.reload(true);
        }
        return ajaxResult;
    }
}
const cesta_dec = function(id_referencia) {
    const inputtext = obtenerObjeto("ref_" + id_referencia);
    let cantidad = inputtext.value;
    if (!isInteger(cantidad))
        return;
    else if (cantidad > 0)
        cantidad = parseInt(cantidad) - 1;
    else
        return;
    if (xmlhttp) {
        ajaxResult = null;
        const url = "ajax/cesta_put.aspx?id_referencia=" + id_referencia + "&cantidad=" + cantidad;
        xmlhttp.open("GET", url, false);
        xmlhttp.onReadyStateChange = respuesta_cesta_put;
        xmlhttp.send(null);
        if (ajaxResult == null)
            respuesta_cesta_put();
        let status = ajaxResult[0];
        let numProductosCesta = ajaxResult[1];
        if ("OK" == status && numProductosCesta != '') {
            document.location.reload(true);
        }
        return ajaxResult;
    }
}
const cesta_remove = function(id_referencia) {
    if (xmlhttp) {
        ajaxResult = null;
        const url = "ajax/cesta_put.aspx?id_referencia=" + id_referencia + "&cantidad=0";
        xmlhttp.open("GET", url, false);
        xmlhttp.onReadyStateChange = respuesta_cesta_put;
        xmlhttp.send(null);
        if (ajaxResult == null)
            respuesta_cesta_put();
        let status = ajaxResult[0];
        let numProductosCesta = ajaxResult[1];
        if ("OK" == status && numProductosCesta != '') {
            document.location.reload(true);
        }
        return ajaxResult;
    }
}
const mensaje = function(mensaje) {
    new Messi(mensaje, { title: 'Mensaje', buttons: [{ id: 0, label: 'Aceptar', val: 'X'}] });
}
window.obtenerObjeto = obtenerObjeto;
window.isInteger = isInteger;
window.mensaje = mensaje;
window.cesta_put = cesta_put;
window.cesta_inc = cesta_inc;
window.cesta_dec = cesta_dec;
window.cesta_remove = cesta_remove;