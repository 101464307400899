const formCarrito3Send = function () {
    $('#carrito3-error').addClass('d-none');
    let url = '/api/formulario/carrito3';
    $('#btn-carrito3-modify').addClass('d-none');
    $('#btn-carrito3-send').addClass('d-none');
    $('#btn-carrito3-sending').removeClass('d-none');
    const payload = {
    };
    $.ajax({
        headers: {
            Accept: 'application/json; charset=utf-8',
            'Content-Type': 'application/json; charset=utf-8'
        },
        type: 'POST',
        url: url,
        data: JSON.stringify(payload),
        dataType: 'json',
        success: function (data, status, jqXHR) {
            if ('OK' == data.status) {
                document.location.href = 'carrito5.aspx';
            } else {
                document.location.href = 'carrito5-ko.html';
            }
        },
        error: function (jqXHR, status) {
            document.location.href = 'carrito5-ko.html';
        }
    });
    return false;
};

const openTpv = function() {
    document.location.href="tpv.aspx";
}
window.formCarrito3Send = formCarrito3Send;
window.openTpv = openTpv;